// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    profileEndpoint: 'https://graph.microsoft.com/v1.0/me',
    clientId: 'c1088d15-1772-4060-9bed-0cd9df12d97e',
    redirectUri: 'https://productsacc.ejgallo.com/',
    authority: 'https://login.microsoftonline.com/3fb1deee-7df8-4d82-9cec-844125522b6f',
    scopes: [
      'user.read',
      'openid',
      'profile'
    ],
    protectedResources: new Map<string, Array<string>>()
      .set('https://graph.microsoft.com/v1.0/me', ['user.read'])
      .set('https://graph.microsoft.com/v1.0/users', ['user.read']),
      myMSGraphProperty: 'est1933a_GalloUser',
      myMSGraph: 'https://graph.microsoft.com/v1.0/me?$select=',
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  