import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';


import { NgxBarcode6Module } from 'ngx-barcode6';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { LightboxModule } from 'ngx-lightbox';

import { Helpers } from './lib/helpers';

import { HttpSecurityInterceptor } from './api/HttpSecurityInterceptor';
import { ProductApiModule } from './api/generated/product/product-api.module';
import { UserDistribApiModule } from './api/generated/userdistrib/user-distrib-api.module';
import { DistributorApiModule } from './api/generated/distributor/distributor-api.module';

import { ProductApiConfiguration } from './api/generated/product/product-api-configuration';
import { UserDistribApiConfiguration } from './api/generated/userdistrib/user-distrib-api-configuration';
import { DistributorApiConfiguration } from './api/generated/distributor/distributor-api-configuration';
import { MsalBroadcastService, MsalGuard, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxBarcode6Module,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TypeaheadModule.forRoot(),
    ButtonsModule.forRoot(),
    BrowserAnimationsModule,
    LightboxModule,
    // all generated services
    ProductApiModule,
    UserDistribApiModule,
    DistributorApiModule,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        redirectUri: environment.redirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => { },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ['https://api.myapplication.com/users/*', ['customscope.read']],
        [environment.redirectUri, null]
      ])
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSecurityInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private productApi: ProductApiConfiguration,
    private userDistribApi: UserDistribApiConfiguration,
    private distributorApi: DistributorApiConfiguration,
  ) {
    this.userDistribApi.rootUrl = Helpers.convertSwaggerUrl('3012', 'v1');
    this.distributorApi.rootUrl = Helpers.convertSwaggerUrl('3017', 'v1');
    this.productApi.rootUrl = Helpers.convertSwaggerUrl('3018', 'v1');
  }
}
