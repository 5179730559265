/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { UserDistribApiConfiguration, UserDistribApiConfigurationInterface } from './user-distrib-api-configuration';

import { ApiService } from './services/api.service';

/**
 * Provider for all UserDistribApi services, plus UserDistribApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    UserDistribApiConfiguration,
    ApiService
  ],
})
export class UserDistribApiModule {
  static forRoot(customParams: UserDistribApiConfigurationInterface): ModuleWithProviders<UserDistribApiModule> {
    return {
      ngModule: UserDistribApiModule,
      providers: [
        {
          provide: UserDistribApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
