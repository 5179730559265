export class Helpers {
  static isLocal(): boolean {
    return window.location.hostname.includes('localhost') || window.location.hostname.includes('mymac');
  }

  static convertSwaggerUrl = (port: string, version: string): string => {
    if (Helpers.isLocal()) {
      return `http://nodejsacc.ejgallo.com:${port}/sas/api/${version}`;
    }
    return `${window.location.protocol}//${window.location.hostname}:${port}/sas/api/${version}`;
  }

  static convertExportUrl = (path: string): string => {
    if (Helpers.isLocal()) {
      return `http://localhost:3000/${path}`;
    } else {
      return `/${path}`;
    }
  }

  static convertUPCAtoUPCE = (upc: string): string => {
    if (!upc.includes('00000')) {
      throw new Error('UPC-A cannot be converted to UPC-E');
    }
    // If the source message string is less than 12 characters long, we make it 12 characters
    if (upc.length < 12) {
      const holdString = '000000000000' + upc;
      upc = holdString.substring(holdString.length - 12, holdString.length);
    }

    if (upc.substring(0, 1) !== '0' && upc.substring(0, 1) !== '1') {
      throw new Error('Invalid Number System (only 0 & 1 are valid)');
    } else {
      if (upc.substring(3, 6) === '000' || upc.substring(3, 6) === '100' ||
        upc.substring(3, 6) === '200') {
        return upc.substring(1, 3) + upc.substring(8, 11) +
          upc.substring(3, 4);
      } else if (upc.substring(4, 6) === '00') {
        return upc.substring(1, 4) + upc.substring(9, 11) + '3';
      } else if (upc.substring(5, 6) === '0') {
        return upc.substring(1, 5) + upc.substring(10, 11) + '4';
      } else if (upc.substring(10, 11) >= '5') {
        return upc.substring(1, 6) + upc.substring(10, 11);
      } else {
        throw new Error('Invalid UPC-A format: Invalid product code (00005 to 00009 are valid)');
      }
    }
  }

  static supportsUPCE = (upc: string): boolean => {
    try {
      Helpers.convertUPCAtoUPCE(upc);
      return true;
    } catch (e) {
      return false;
    }
  }

}
