import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class HttpSecurityInterceptor implements HttpInterceptor {
  constructor(private _http: HttpClient) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = sessionStorage.getItem('Authorization Bearer ');
    if (accessToken) {
      const headers = new HttpHeaders({'Authorization': `Bearer ${accessToken}`});
      request = request.clone({
        // withCredentials: true,
        headers,
      });
    }
    
    return next.handle(request);
  }
}
