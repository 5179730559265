/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DistributorApiConfiguration, DistributorApiConfigurationInterface } from './distributor-api-configuration';

import { ApiService } from './services/api.service';

/**
 * Provider for all DistributorApi services, plus DistributorApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    DistributorApiConfiguration,
    ApiService
  ],
})
export class DistributorApiModule {
  static forRoot(customParams: DistributorApiConfigurationInterface): ModuleWithProviders<DistributorApiModule> {
    return {
      ngModule: DistributorApiModule,
      providers: [
        {
          provide: DistributorApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
