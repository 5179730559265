/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for DistributorApi services
 */
@Injectable({
  providedIn: 'root',
})
export class DistributorApiConfiguration {
  rootUrl: string = 'https://nodejsdev.ejgallo.com:3017/sas/api/v1';
}

export interface DistributorApiConfigurationInterface {
  rootUrl?: string;
}
