/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ProductApiConfiguration as __Configuration } from '../product-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProductList } from '../models/product-list';
import { Product } from '../models/product';
import { ExportList } from '../models/export-list';
import { FilterList } from '../models/filter-list';
import { BrandList } from '../models/brand-list';
import { JSONResponse } from '../models/jsonresponse';
@Injectable({
  providedIn: 'root',
})
class ApiService extends __BaseService {
  static readonly getProductsPath = '/product';
  static readonly getProductPath = '/product/{id}';
  static readonly getExportListPath = '/exportlist';
  static readonly getFiltersPath = '/filterlist';
  static readonly getBrandsPath = '/brandlist';
  static readonly getPerfPath = '/perf';
  static readonly getHeartbeatPath = '/heartbeat';
  static readonly aboutPath = '/about';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * # Product service
   * Returns a list of products.
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only products the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   *
   * (Note: pageno and pagesize are required)
   * @param params The `ApiService.GetProductsParams` containing the following parameters:
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `upcid`: Optional filter by UPC - supports partial matches
   *
   * - `skuid`: Optional filter by SKU ID (Full SKU) - supports partial matches
   *
   * - `sizeid`: Optional filter by size ID
   *
   * - `flavorid`: Optional filter by flavor ID
   *
   * - `distskuid`: Optional filter by distributor SKU ID - supports partial matches
   *
   * - `dist`: Distributor billTo id
   *
   * - `destination`: Optional filter by destination DOMESTIC or INTERNATIONAL
   *
   * - `brandid`: Optional filter by brand ID
   *
   * - `appellationid`: Optional filter by appellation ID
   *
   * @return A list of Products
   */
  getProductsResponse(params: ApiService.GetProductsParams): __Observable<__StrictHttpResponse<Array<ProductList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.pageno != null) __params = __params.set('pageno', params.pageno.toString());
    if (params.upcid != null) __params = __params.set('upcid', params.upcid.toString());
    if (params.skuid != null) __params = __params.set('skuid', params.skuid.toString());
    if (params.sizeid != null) __params = __params.set('sizeid', params.sizeid.toString());
    if (params.flavorid != null) __params = __params.set('flavorid', params.flavorid.toString());
    if (params.distskuid != null) __params = __params.set('distskuid', params.distskuid.toString());
    if (params.dist != null) __params = __params.set('dist', params.dist.toString());
    if (params.destination != null) __params = __params.set('destination', params.destination.toString());
    if (params.brandid != null) __params = __params.set('brandid', params.brandid.toString());
    if (params.appellationid != null) __params = __params.set('appellationid', params.appellationid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/product`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductList>>;
      })
    );
  }
  /**
   * # Product service
   * Returns a list of products.
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only products the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   *
   * (Note: pageno and pagesize are required)
   * @param params The `ApiService.GetProductsParams` containing the following parameters:
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `upcid`: Optional filter by UPC - supports partial matches
   *
   * - `skuid`: Optional filter by SKU ID (Full SKU) - supports partial matches
   *
   * - `sizeid`: Optional filter by size ID
   *
   * - `flavorid`: Optional filter by flavor ID
   *
   * - `distskuid`: Optional filter by distributor SKU ID - supports partial matches
   *
   * - `dist`: Distributor billTo id
   *
   * - `destination`: Optional filter by destination DOMESTIC or INTERNATIONAL
   *
   * - `brandid`: Optional filter by brand ID
   *
   * - `appellationid`: Optional filter by appellation ID
   *
   * @return A list of Products
   */
  getProducts(params: ApiService.GetProductsParams): __Observable<Array<ProductList>> {
    return this.getProductsResponse(params).pipe(
      __map(_r => _r.body as Array<ProductList>)
    );
  }

  /**
   * # Product service
   * Returns a single product with {id}.
   *
   * Note: does not check eligibility - no sensitive info. Accepts dist param for consistency but does not use it.
   * @param params The `ApiService.GetProductParams` containing the following parameters:
   *
   * - `id`: Product id to filter (Full SKU / Material ID)
   *
   * - `dist`: Distributor billTo id
   *
   * @return A single Product details
   */
  getProductResponse(params: ApiService.GetProductParams): __Observable<__StrictHttpResponse<Product>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.dist != null) __params = __params.set('dist', params.dist.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/product/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Product>;
      })
    );
  }
  /**
   * # Product service
   * Returns a single product with {id}.
   *
   * Note: does not check eligibility - no sensitive info. Accepts dist param for consistency but does not use it.
   * @param params The `ApiService.GetProductParams` containing the following parameters:
   *
   * - `id`: Product id to filter (Full SKU / Material ID)
   *
   * - `dist`: Distributor billTo id
   *
   * @return A single Product details
   */
  getProduct(params: ApiService.GetProductParams): __Observable<Product> {
    return this.getProductResponse(params).pipe(
      __map(_r => _r.body as Product)
    );
  }

  /**
   * # Product service - export
   * Returns a large set of attributes per product for products mathcing optional filter criteria - or all products if not filtered
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only product attributes the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   *
   * (Note: pageno and pagesize are optional to enable pagination - if pagination is desired then both are required to enable it)
   * @param params The `ApiService.GetExportListParams` containing the following parameters:
   *
   * - `upcid`: Optional filter by UPC - supports partial matches
   *
   * - `skuid`: Optional filter by SKU ID (Full SKU) - supports partial matches
   *
   * - `sizeid`: Optional filter by size ID
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `flavorid`: Optional filter by flavor ID
   *
   * - `distskuid`: Optional filter by distributor SKU ID - supports partial matches
   *
   * - `dist`: Distributor billTo id
   *
   * - `destination`: Optional filter by destination DOMESTIC or INTERNATIONAL
   *
   * - `brandid`: Optional filter by brand ID
   *
   * - `appellationid`: Optional filter by appellation ID
   *
   * @return A list of filter attributes
   */
  getExportListResponse(params: ApiService.GetExportListParams): __Observable<__StrictHttpResponse<Array<ExportList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.upcid != null) __params = __params.set('upcid', params.upcid.toString());
    if (params.skuid != null) __params = __params.set('skuid', params.skuid.toString());
    if (params.sizeid != null) __params = __params.set('sizeid', params.sizeid.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.pageno != null) __params = __params.set('pageno', params.pageno.toString());
    if (params.flavorid != null) __params = __params.set('flavorid', params.flavorid.toString());
    if (params.distskuid != null) __params = __params.set('distskuid', params.distskuid.toString());
    if (params.dist != null) __params = __params.set('dist', params.dist.toString());
    if (params.destination != null) __params = __params.set('destination', params.destination.toString());
    if (params.brandid != null) __params = __params.set('brandid', params.brandid.toString());
    if (params.appellationid != null) __params = __params.set('appellationid', params.appellationid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/exportlist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ExportList>>;
      })
    );
  }
  /**
   * # Product service - export
   * Returns a large set of attributes per product for products mathcing optional filter criteria - or all products if not filtered
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only product attributes the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   *
   * (Note: pageno and pagesize are optional to enable pagination - if pagination is desired then both are required to enable it)
   * @param params The `ApiService.GetExportListParams` containing the following parameters:
   *
   * - `upcid`: Optional filter by UPC - supports partial matches
   *
   * - `skuid`: Optional filter by SKU ID (Full SKU) - supports partial matches
   *
   * - `sizeid`: Optional filter by size ID
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `flavorid`: Optional filter by flavor ID
   *
   * - `distskuid`: Optional filter by distributor SKU ID - supports partial matches
   *
   * - `dist`: Distributor billTo id
   *
   * - `destination`: Optional filter by destination DOMESTIC or INTERNATIONAL
   *
   * - `brandid`: Optional filter by brand ID
   *
   * - `appellationid`: Optional filter by appellation ID
   *
   * @return A list of filter attributes
   */
  getExportList(params: ApiService.GetExportListParams): __Observable<Array<ExportList>> {
    return this.getExportListResponse(params).pipe(
      __map(_r => _r.body as Array<ExportList>)
    );
  }

  /**
   * # Product service filters
   * Returns a list of brand,flavor,size,appellation,destination combinations to use for filtering
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only product attributes the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   *
   * (Note: pageno and pagesize are required)
   * @param params The `ApiService.GetFiltersParams` containing the following parameters:
   *
   * - `sizeid`: Optional filter by size ID
   *
   * - `flavorid`: Optional filter by flavor ID
   *
   * - `dist`: Distributor billTo id
   *
   * - `destination`: Optional filter by destination DOMESTIC or INTERNATIONAL
   *
   * - `brandid`: Optional filter by brand ID
   *
   * - `appellationid`: Optional filter by appellation ID
   *
   * @return A list of filter attributes
   */
  getFiltersResponse(params: ApiService.GetFiltersParams): __Observable<__StrictHttpResponse<Array<FilterList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sizeid != null) __params = __params.set('sizeid', params.sizeid.toString());
    if (params.flavorid != null) __params = __params.set('flavorid', params.flavorid.toString());
    if (params.dist != null) __params = __params.set('dist', params.dist.toString());
    if (params.destination != null) __params = __params.set('destination', params.destination.toString());
    if (params.brandid != null) __params = __params.set('brandid', params.brandid.toString());
    if (params.appellationid != null) __params = __params.set('appellationid', params.appellationid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filterlist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FilterList>>;
      })
    );
  }
  /**
   * # Product service filters
   * Returns a list of brand,flavor,size,appellation,destination combinations to use for filtering
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only product attributes the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   *
   * (Note: pageno and pagesize are required)
   * @param params The `ApiService.GetFiltersParams` containing the following parameters:
   *
   * - `sizeid`: Optional filter by size ID
   *
   * - `flavorid`: Optional filter by flavor ID
   *
   * - `dist`: Distributor billTo id
   *
   * - `destination`: Optional filter by destination DOMESTIC or INTERNATIONAL
   *
   * - `brandid`: Optional filter by brand ID
   *
   * - `appellationid`: Optional filter by appellation ID
   *
   * @return A list of filter attributes
   */
  getFilters(params: ApiService.GetFiltersParams): __Observable<Array<FilterList>> {
    return this.getFiltersResponse(params).pipe(
      __map(_r => _r.body as Array<FilterList>)
    );
  }

  /**
   * # Brand list service
   * Returns a list of brands
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only brands the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   * If dist param is not passed and the userType=distributor, then the resultset will be tht unique combination of all brands assigned to all billtos the user is assigned to.
   * @param dist Distributor billTo id
   * @return A list of brands
   */
  getBrandsResponse(dist?: number): __Observable<__StrictHttpResponse<Array<BrandList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (dist != null) __params = __params.set('dist', dist.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/brandlist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BrandList>>;
      })
    );
  }
  /**
   * # Brand list service
   * Returns a list of brands
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered by eligibility to only brands the distributor is eligble for. (note: dist parameter is validated to ensure the user has access to that distributor)
   * If dist param is not passed and the userType=distributor, then the resultset will be tht unique combination of all brands assigned to all billtos the user is assigned to.
   * @param dist Distributor billTo id
   * @return A list of brands
   */
  getBrands(dist?: number): __Observable<Array<BrandList>> {
    return this.getBrandsResponse(dist).pipe(
      __map(_r => _r.body as Array<BrandList>)
    );
  }

  /**
   * Returns server/service metrics
   * @return Success
   */
  getPerfResponse(): __Observable<__StrictHttpResponse<JSONResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/perf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JSONResponse>;
      })
    );
  }
  /**
   * Returns server/service metrics
   * @return Success
   */
  getPerf(): __Observable<JSONResponse> {
    return this.getPerfResponse().pipe(
      __map(_r => _r.body as JSONResponse)
    );
  }

  /**
   * Hearbeat returns status of service and ability to query Hana EDW
   * @return Success
   */
  getHeartbeatResponse(): __Observable<__StrictHttpResponse<JSONResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/heartbeat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JSONResponse>;
      })
    );
  }
  /**
   * Hearbeat returns status of service and ability to query Hana EDW
   * @return Success
   */
  getHeartbeat(): __Observable<JSONResponse> {
    return this.getHeartbeatResponse().pipe(
      __map(_r => _r.body as JSONResponse)
    );
  }

  /**
   * Returns basic information about the service and server
   * @return Success
   */
  aboutResponse(): __Observable<__StrictHttpResponse<JSONResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/about`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JSONResponse>;
      })
    );
  }
  /**
   * Returns basic information about the service and server
   * @return Success
   */
  about(): __Observable<JSONResponse> {
    return this.aboutResponse().pipe(
      __map(_r => _r.body as JSONResponse)
    );
  }
}

module ApiService {

  /**
   * Parameters for getProducts
   */
  export interface GetProductsParams {

    /**
     * Number of products to return per page
     */
    pagesize: number;

    /**
     * Page number in a paged result set - pageno starts at 1
     */
    pageno: number;

    /**
     * Optional filter by UPC - supports partial matches
     */
    upcid?: string;

    /**
     * Optional filter by SKU ID (Full SKU) - supports partial matches
     */
    skuid?: string;

    /**
     * Optional filter by size ID
     */
    sizeid?: string;

    /**
     * Optional filter by flavor ID
     */
    flavorid?: string;

    /**
     * Optional filter by distributor SKU ID - supports partial matches
     */
    distskuid?: string;

    /**
     * Distributor billTo id
     */
    dist?: number;

    /**
     * Optional filter by destination DOMESTIC or INTERNATIONAL
     */
    destination?: 'DOMESTIC' | 'INTERNATIONAL';

    /**
     * Optional filter by brand ID
     */
    brandid?: string;

    /**
     * Optional filter by appellation ID
     */
    appellationid?: string;
  }

  /**
   * Parameters for getProduct
   */
  export interface GetProductParams {

    /**
     * Product id to filter (Full SKU / Material ID)
     */
    id: string;

    /**
     * Distributor billTo id
     */
    dist?: number;
  }

  /**
   * Parameters for getExportList
   */
  export interface GetExportListParams {

    /**
     * Optional filter by UPC - supports partial matches
     */
    upcid?: string;

    /**
     * Optional filter by SKU ID (Full SKU) - supports partial matches
     */
    skuid?: string;

    /**
     * Optional filter by size ID
     */
    sizeid?: string;

    /**
     * Number of products to return per page
     */
    pagesize?: number;

    /**
     * Page number in a paged result set - pageno starts at 1
     */
    pageno?: number;

    /**
     * Optional filter by flavor ID
     */
    flavorid?: string;

    /**
     * Optional filter by distributor SKU ID - supports partial matches
     */
    distskuid?: string;

    /**
     * Distributor billTo id
     */
    dist?: number;

    /**
     * Optional filter by destination DOMESTIC or INTERNATIONAL
     */
    destination?: 'DOMESTIC' | 'INTERNATIONAL';

    /**
     * Optional filter by brand ID
     */
    brandid?: string;

    /**
     * Optional filter by appellation ID
     */
    appellationid?: string;
  }

  /**
   * Parameters for getFilters
   */
  export interface GetFiltersParams {

    /**
     * Optional filter by size ID
     */
    sizeid?: string;

    /**
     * Optional filter by flavor ID
     */
    flavorid?: string;

    /**
     * Distributor billTo id
     */
    dist?: number;

    /**
     * Optional filter by destination DOMESTIC or INTERNATIONAL
     */
    destination?: 'DOMESTIC' | 'INTERNATIONAL';

    /**
     * Optional filter by brand ID
     */
    brandid?: string;

    /**
     * Optional filter by appellation ID
     */
    appellationid?: string;
  }
}

export { ApiService }
