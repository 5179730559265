import {Component, OnInit} from '@angular/core';
import {ApiService as UserDistributorApiService} from '../app/api/generated/userdistrib/services/api.service';
import {ApiService as DistributorService} from '../app/api/generated/distributor/services/api.service';
import {UserResponse} from './api/generated/userdistrib/models';
import {CountryList} from './api/generated/distributor/models/country-list';
import {StateList} from './api/generated/distributor/models/state-list';
import {DistributorList} from './api/generated/distributor/models/distributor-list';
import {IDistributor, StateManagementService} from './lib/state-management.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {RestService} from './lib/rest.service';
import {Helpers} from './lib/helpers'; // this is a lie
import { MsalService } from '@azure/msal-angular';


export interface ICountry extends CountryList {
  states: StateList[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit {

  public loggedIn: boolean = false;
  public isDistributorDirty: boolean = false;
  public typeaheadOptionsLimit: number = 10;

  public countries: ICountry[] = [];
  public selectedCountry: string = '';

  public states: StateList[] = [];
  public selectedState: string = '';

  public distributors: IDistributor[] = [];
  public isSearchingDistributors: boolean = true;

  public get selectedDistributor(): string {
    return this.stateManagerService.selectedDistributor ? this.stateManagerService.selectedDistributor.value : '';
  }

  public set selectedDistributor(distributorValue: string) {
    if (!distributorValue) {
      this.stateManagerService.selectedDistributor = null;
      this.isDistributorDirty = true;
    }
  }

  constructor(
    private userDistributorApiService: UserDistributorApiService,
    private distributorService: DistributorService,
    private restService: RestService,
    public stateManagerService: StateManagementService,
    private authService: MsalService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.handleAzureLogin();
      const userDistrib = await this.userDistributorApiService.getUserDistrib().toPromise();
      this.stateManagerService.setUser(userDistrib);

      if (this.stateManagerService.isWineryUser) {
        this.loadCountriesAndStates();
        this.searchDistributors();
      } else {
        this.processShipTo();
      }
      const serverConfig = await this.restService.get(Helpers.convertExportUrl('config'));
      if (serverConfig) {
        this.stateManagerService.galloEnv = serverConfig.galloEnv;
        this.stateManagerService.version = serverConfig.version;
        if (this.stateManagerService.isDev()) {
            this.stateManagerService.externalServiceUrl = 'https://gateway-dev.fbwineapps.com/gateway-external-services/ContentHubAsset/get_gch_content?gateway_token=f85f803c-f7b3-4368-b751-3bddeaae550a';
        } else if (this.stateManagerService.isAcc()) {
            this.stateManagerService.externalServiceUrl = 'https://gateway-acc.fbwineapps.com/gateway-external-services/ContentHubAsset/get_gch_content?gateway_token=f85f803c-f7b3-4368-b751-3bddeaae550a';
        }
      }
    } catch (error) {

    }
  }

  async handleAzureLogin(): Promise<void> {
    try {
      const res = await this.authService.instance.handleRedirectPromise();
      if (res && res.account) {
        this.authService.instance.setActiveAccount(res.account)
        // Saves access token to session storage to retrieve in service to send back to api
        sessionStorage.setItem('Authorization Bearer ', res.accessToken);
        // sets oid of logged in user to a new header attribute to be passed back to api
        var obj = JSON.parse(JSON.stringify(res.idTokenClaims));
        sessionStorage.setItem('X-AAD-OID ', obj.oid);
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
        this.authService.loginRedirect();
      }
    } catch (error) {
      this.loggedIn = false;
      console.log(error);
      alert('Authentication error: ' + error && error.message ? error.message : JSON.stringify(error));
    }
    

  }

  public onCountrySelect(): void {
    if (this.selectedCountry) {
      this.typeaheadOptionsLimit = null;
      this.states = this.countries.find(f => f.id === this.selectedCountry).states;
    } else {
      this.typeaheadOptionsLimit = 10;
      this.states = [];
    }
    this.selectedState = '';

    this.searchDistributors();
  }

  public onStateSelected(): void {
    this.searchDistributors();
  }

  /**
   * This is effectively `public set selectedDistributor`
   * @param event
   */
  public onTypeaheadDistributorSelect(event: TypeaheadMatch): void {
    this.stateManagerService.selectedDistributor = event.item;
    this.isDistributorDirty = true;
  }

  public onDistributorSelectById(value: string) {
    this.stateManagerService.selectedDistributor = this.distributors.find(f => f.value === value);
    this.isDistributorDirty = true;
  }

  public submitDistributorSelection(): void {
    this.stateManagerService.onDistributorSet();
    this.isDistributorDirty = false;
  }

  private async searchDistributors(): Promise<void> {
    this.isSearchingDistributors = true;
    const params: DistributorService.GetShipToDistributorsParams = { };
    if (this.selectedCountry) params.countryid = this.selectedCountry;
    if (this.selectedState) params.stateid = this.selectedState;
    const distributors = await this.distributorService.getShipToDistributors(params).toPromise();
    this.distributors = distributors.map((distributor: IDistributor) => {
      let conditionalStateCountry = '';
      if (!this.selectedState) {
        conditionalStateCountry += ', ';
        conditionalStateCountry += distributor.state;
      }
      if (!this.selectedCountry) {
        if (this.selectedState || (!this.selectedState && distributor.state)) conditionalStateCountry += ', ';
        conditionalStateCountry += distributor['country'];
      }
      distributor.value = `${distributor.id} - ${distributor.city}${conditionalStateCountry} - ${distributor.description}`;
      return distributor;
    });

    this.isSearchingDistributors = false;
  }

  private async loadCountriesAndStates(): Promise<void> {
    const [countries, states] = await Promise.all([
      this.distributorService.getCountries().toPromise(),
      this.distributorService.getStates().toPromise()
    ]);

    this.countries = countries.map(country => {
      country['states'] = states.filter(f => country.id === f.countryid);
      return country;
    }) as ICountry[];
  }

  /**
   * Converts the shipTo list from userdistrib to IDistributor
   */
  private processShipTo(): void {
    this.distributors = this.stateManagerService.user.shipTo.map(shipTo => {
      return {
        id: shipTo.CustNbr + '', // Type is incorrect, id is always a number
        value: shipTo.CustNbr + ' - ' + shipTo.CustName, // Value is being used as the unique key for the select
        description: shipTo.CustName,
        city: null,
        state: null
      };
    });

    this.stateManagerService.selectedDistributor = this.distributors[0];
    this.stateManagerService.onDistributorSet();
  }

}
