/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { UserDistribApiConfiguration as __Configuration } from '../user-distrib-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserResponse } from '../models/user-response';
import { JSONResponse } from '../models/jsonresponse';
@Injectable({
  providedIn: 'root',
})
class ApiService extends __BaseService {
  static readonly getUserDistribPath = '/userdistrib';
  static readonly getPerfPath = '/perf';
  static readonly getHeartbeatPath = '/heartbeat';
  static readonly aboutPath = '/about';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Basic user info and list of distributors if userType=distributor
   * @return Success
   */
  getUserDistribResponse(): __Observable<__StrictHttpResponse<UserResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/userdistrib`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserResponse>;
      })
    );
  }
  /**
   * Basic user info and list of distributors if userType=distributor
   * @return Success
   */
  getUserDistrib(): __Observable<UserResponse> {
    return this.getUserDistribResponse().pipe(
      __map(_r => _r.body as UserResponse)
    );
  }

  /**
   * Returns server/service metrics
   * @return Success
   */
  getPerfResponse(): __Observable<__StrictHttpResponse<JSONResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/perf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JSONResponse>;
      })
    );
  }
  /**
   * Returns server/service metrics
   * @return Success
   */
  getPerf(): __Observable<JSONResponse> {
    return this.getPerfResponse().pipe(
      __map(_r => _r.body as JSONResponse)
    );
  }

  /**
   * Hearbeat returns status of service and ability to query Hana EDW
   * @return Success
   */
  getHeartbeatResponse(): __Observable<__StrictHttpResponse<JSONResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/heartbeat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JSONResponse>;
      })
    );
  }
  /**
   * Hearbeat returns status of service and ability to query Hana EDW
   * @return Success
   */
  getHeartbeat(): __Observable<JSONResponse> {
    return this.getHeartbeatResponse().pipe(
      __map(_r => _r.body as JSONResponse)
    );
  }

  /**
   * Returns basic information about the service and server
   * @return Success
   */
  aboutResponse(): __Observable<__StrictHttpResponse<JSONResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/about`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JSONResponse>;
      })
    );
  }
  /**
   * Returns basic information about the service and server
   * @return Success
   */
  about(): __Observable<JSONResponse> {
    return this.aboutResponse().pipe(
      __map(_r => _r.body as JSONResponse)
    );
  }
}

module ApiService {
}

export { ApiService }
